/* eslint-disable no-undef */
( function ( $ ) {
  //active 'All' checkbox when reset button click
  $( '.reset-filters' ).on( 'click', function () {
    $( '.facet-checkbox-select-all' ).addClass( 'checked' );
  } );

  //on page load hide the reset button
  if ( $( '.facetwp-selection-value' ).length <= 0 ) {
    $( '.reset-selection' ).hide();
  }

  //'All' check box on click action
  $( '.facet-checkbox-select-all' ).on( 'click', ( event ) => {
    $( event.target ).addClass( 'checked' );
  } );

  $( document ).on( 'facetwp-loaded', () => {
    let queryString = FWP.buildQueryString();
    if ( '' === queryString ) {
      // no facets are selected
      $( '.reset-selection' ).hide();
    } else {
      $( '.reset-selection' ).show();
    }

    //remove facetwp-all-* class when any facet has selected item
    const facets = FWP.facets;
    $.each( facets, ( key ) => {
      if ( facets[key].length ) {
        $( `.facetwp-all-${key}` ).removeClass( 'checked' );
      } else {
        $( `.facetwp-all-${key}` ).addClass( 'checked' );
      }
    } );
  } );
} )( jQuery );

/* eslint-disable no-undef */
( function ( $ ) {
  $( document ).on( 'click', '.facetwp-page', ( e ) => {
    const adminBarHeight = $( '#wpadminbar' ).length > 0 ? $( '#wpadminbar' ).outerHeight() : 0;
    const headerHeight = $( 'header' ).outerHeight() + adminBarHeight;
    const targetSectionTop = $( e.currentTarget ).closest( 'section' ).offset().top;
    $( 'html, body' ).animate(
      { scrollTop: targetSectionTop - headerHeight },
      500
    );
  } );
} )( jQuery );

$( () => {
  addSpanCollapseIcon( '.header--nav li', 'ul' );

  clickToggle( '.header--nav span.drop-down', 'ul', 'previous', 'active', 'toggle-active', 400 );

  /*** Side bar category navigation  ***/
  clickToggle( '.active-mobile .widgettitle', 'ul', 'next', 'active', 'toggle-active', 400 );

  let headerHamburger = $( '.header--hamburger' );

  headerHamburger.click( function () {
    let _this = $( this );
    $( 'body' ).toggleClass( 'menu-active' );
    $( 'html' ).toggleClass( 'menu-visible' );
    _this.toggleClass( 'active' );
  } );

  $( '.icon' ).on( 'click', () => {
    $( this ).parent().toggleClass( 'active' );
    let code = $( '.search' );
    code.toggleClass( 'active' );
    const hasclass = code.hasClass( 'active' );
    hasClass( hasclass, code );     
  } );

  $( '.menu-item a' ).each( ( index, element ) => {
    isNoLink( element );    
  } );

  $( '#menu-footer-main-menu .menu-item-has-children' ).each( ( index, element ) => {
    const $this = $( element );
    $this.append( '<span class=\'dropdown\'></span>' );
    $this.toggleClass( 'expand', $this.find( '.active-tab' ).length > 0 );
    $this.children( 'a:first-child' ).on( 'click', ( e ) => {
      e.preventDefault();
      toggleExpand( $this );    
    } );
  } );

  const $bsWpMenuTab = $( '.bs-wp-menu-tab' );
  if ( $bsWpMenuTab.length ) {
    const $menuItems = $bsWpMenuTab.find( '.menu-item a[rel!=""]' );
    $menuItems.each( function() {
      const rel = this.getAttribute( 'rel' );
      $( 'header .menu-item.' + rel ).addClass( 'active-tab' );
    } );
  }

  $( '.sub-menu li' ).each( ( index, element ) => {
    const $this = $( element );
    if ( $this.hasClass( 'active-tab' ) ) {
      $this.parents( '.menu-item' ).addClass( 'active-tab' );
      enablePointer( $this );
    }
  } );

  function enablePointer( $this ){
    const $menuItem = $this.parents( '.menu-item' );
    const $menuItemAnchor = $menuItem.children( 'a' );
    const href = $menuItemAnchor.attr( 'href' );
    if ( href !== '' && href !== '#' ) {
      $menuItemAnchor.addClass( 'enable-pointer' );
    }
  } 

  $( 'main' ).each( ( index, element ) => {
    const $itemCat = $( element ).attr( 'data-category' ),
      $selectedCategory = $( element ).find( '#selected-category' );
    let $selectedCat = '';
    if ( $( $selectedCategory !== undefined ) ) {
      $selectedCat = $selectedCategory.val();
    }
    if ( $itemCat !== undefined ) {
      const $activeMenu = $( '.menu-item.' + $itemCat );
      $activeMenu.addClass( 'current_page_item active-tab enable-pointer' )
        .children( 'a' ).addClass( 'enable-pointer' );
      $activeMenu.parents( '.menu-item' ).addClass( 'current_page_item active-tab enable-pointer' )
        .children( 'a' ).addClass( 'enable-pointer' );
    }
    if ( $selectedCat !== undefined ) {
      const $activeMenu = $( '.menu-item.' + $selectedCat );
      $activeMenu.addClass( 'current_page_item active-tab enable-pointer' )
        .children( 'a' ).addClass( 'enable-pointer' );
      $activeMenu.parents( '.menu-item' ).addClass( 'current_page_item active-tab enable-pointer' )
        .children( 'a' ).addClass( 'enable-pointer' );
    }
  } );

  if ( window.location.href.indexOf( 'search' ) > -1 ) {
    $( '.menu-item.active-tab' ).children( 'a' ).addClass( 'enable-pointer' );
  }

  const displayWindowSize = () => {
    const winWidth = document.documentElement.clientWidth;    
    if ( winWidth >= 1200 ) {
      $( '.sub-menu' ).attr( 'style', '' );
    }
  };
  window.addEventListener( 'resize', displayWindowSize );
  displayWindowSize();

} );

const addSpanCollapseIcon = ( targetPar, childElem ) => {
  let targetParent = $( targetPar ),
    childElement = childElem;
  let headerSpanIconElement = '<span class="drop-down"> </span>';
  targetParent.each( function () {
    let _this = $( this );
    if ( _this.find( childElement ).length ) {
      _this.append( headerSpanIconElement );
    }
  } );
};

const clickToggle = ( ClickEle, targetElem, tarEleType, addingClass, toggleActive, toggleDura ) => {
  let clickElement = $( ClickEle ),
    targetElement = targetElem,
    elementType = tarEleType,
    activeClass = addingClass,
    toggleDuration = toggleDura;
  clickElement.click( function () {
    let _this = $( this );
    if ( elementType == 'previous' ) {
      _this.prev( targetElement ).slideToggle( toggleDuration );
    } else if ( elementType == 'next' ) {
      _this.next( targetElement ).slideToggle( toggleDuration );
    } else {
      $( targetElement ).slideToggle( toggleDuration );
      $( targetElement ).toggleClass( activeClass );
    }
    _this.toggleClass( activeClass );
    _this.parent().toggleClass( toggleActive );
  } );
};

function hasClass( hasclass, code ) {
  if ( hasclass ) {
    return code.focus();
  } else {
    code.blur().val( '' );
  }
}

function isNoLink( element ){
  if ( element.getAttribute( 'href' ) === '' || element.getAttribute( 'href' ) === '#' ) {
    element.classList.add( 'no-link' );
  }
}

if ( $( window ).width() < 768 ) {
  toggleExpand( $( this ) );
}

function toggleExpand( $element ) {
  $element.siblings().removeClass( 'expand' );
  $element.toggleClass( 'expand' );
}
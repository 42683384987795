$( () => {
  // To refresh the initiated slick sliders
  const refreshSlick = () => {
    $( '.slick-initialized' ).each( function ( key ) {
      if ( $( '.slick-initialized' )[key]?.slick ) {
        $( '.slick-initialized' )[key].slick.refresh();
      }
    } );
  };

  const $tabItems = $( '.bs-section--home-tab-news-event .bs-slider-accordion .card' );
  $tabItems.on( 'click', function() { 
    refreshSlick();
  } );
} );  
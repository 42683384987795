// Define debounce function
function debounce( func, wait ) {
  let timeout;
  return function executedFunction( ...args ) {
    const later = () => {
      timeout = null;
      func( ...args );
    };
    clearTimeout( timeout );
    timeout = setTimeout( later, wait );
  };
}

// jQuery document ready function
$( document ).ready( () => {
  const accordionWrapper = $( '.bs-slider-accordion' );
  if ( accordionWrapper.length === 0 ) return;
  const header = $( 'header' );
  const breakpoint = 1200; // You can adjust this breakpoint as needed

  // Click event handler for card headers
  accordionWrapper.find( '.card-header' ).on( 'click', debounce( ( e ) => {
    setTimeout( () => {
      const content = $( e.target ).closest( '.card-header' ).next();
      const card = content.parent( '.card' );
      if ( $( window ).width() < breakpoint && card.hasClass( 'bs-active' ) ) {
        const scrollValue = card.offset().top - header.height() - 10;
        window.scrollTo( { top: scrollValue, behavior: 'smooth' } );
      }
    }, 600 ); // 500ms delay after the click event
  }, 105 ) ); // 105ms debounce time
} );

let $ul = $( '.bs-section--about-us-nav ul' );
$ul.addClass( 'open-menu' );

function mobileMenu() {
  if( $( window ).width() <= 767 ){
    if( !$ul.data( 'sorted' ) ){
      $ul.data( 'original', $ul.html() );
      $ul.data( 'sorted', true );
      setTimeout( function(){
        $ul.prepend( $ul.find( 'li.current-menu-item' ) );
      }, 800 );
    } else {
      $ul.data( 'sorted', false );
      $ul.html( $ul.data( 'original' ) );
    }
  } else {
    $ul.data( 'sorted', false );
    $ul.html( $ul.data( 'original' ) );
  }
}

mobileMenu();

let previousWidth = jQuery( window ).width();
$( window ).resize( function(){
  let currentWidth = jQuery( window ).width();
  let threshold = 767;
  if ( previousWidth < threshold && currentWidth >= threshold )
    mobileMenu();
  if ( previousWidth >= threshold && currentWidth < threshold )
    mobileMenu();
  previousWidth = currentWidth;
} );


$( '.toggle-button' ).click( function(){
  $( this ).toggleClass( 'open' );
  setTimeout( function(){
    $ul.toggleClass( 'open-menu' );
  }, 100 );
  mobileMenu();
} );
